<template>
  <AuthLayout>
    <form class="grid grid-cols-1 gap-6" @submit.prevent="submit()">
      <div class="col-span-1" v-if="success || validationErrors.length > 0">
        <ValidationErrorMessage :errors="validationErrors" />
        <Alert v-if="success" type="success">We hebben je een e-mail gestuurd!</Alert>
      </div>
      <div class="col-span-1">
        <p class="text-xl text-prasset-green-500 font-semibold text-center">
          {{ $tuf('recover_password') }}
        </p>
      </div>
      <div class="col-span-1">
        <label for="email" class="text-sm text-prasset-green-800 mb-2 block">{{ $tuf('emailaddress') }}</label>
        <TextField
          id="email"
          type="email"
          :placeholder="$t('emailaddress')"
          :required="true"
          name="email"
          v-model="form.email"
        />
      </div>
      <div class="col-span-1 flex flex-col items-center">
        <button class="button button--opague w-full max-w-xs" type="submit">
          {{ $tuf('recover_password') }}
        </button>
        <router-link
          :to="{ name: 'login' }"
          class="text-xs text-prasset-green-800 mt-6"
        >
          {{ $tuf('back_to_login') }}
        </router-link>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import Alert from '@/modules/core/views/components/Alert';
import Api from '@/providers/api';
import AuthLayout from '@/modules/auth/views/layouts/auth';
import TextField from '@/components/field/Text';
import ValidationErrorMessage from '@/modules/core/views/components/ValidationErrorMessage';

export default {
  name: 'Recover',

  components: {
    ValidationErrorMessage,
    AuthLayout,
    Alert,
    TextField,
  },

  data() {
    return {
      validationErrors: [],
      success: false,
      form: {
        email: '',
      },
    };
  },

  mounted() {
    this.form.email = this.$route.query.email;
  },

  methods: {
    submit() {
      this.success = false;
      this.validationErrors = [];

      const resetUrl = `${window.location.origin}/password/reset`;

      Api()
        .post('/auth/password/recover', { ...this.form, url: resetUrl })
        .then(() => {
          this.form.email = '';
          this.success = true;
        })
        .catch(({ response }) => {
          const data = response.data;
          this.validationErrors = data.errors
            ? data.errors
            : { message: [data.message] };
        });
    },
  },
};
</script>
